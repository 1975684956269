// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  prefixUrl: window["environment"]["prefixUrl"] || "",
  authLocalStorageTokenName:
    window["environment"]["authLocalStorageTokenName"] || "",
  ssoDomain: window["environment"]["ssoDomain"] || "",
  aktifProjeFirmaKey: window["environment"]["aktifProjeFirmaKey"] || "",
  signinpath: window["environment"]["signinpath"] || "",
  homeurl: window["environment"]["homeurl"] || "",
  uygulamaId: window["environment"]["uygulamaId"] || "",
  projeAnasayfaUrl: window["environment"]["projeAnasayfaUrl"] || "",
  favoriBolumuGosterilecekUygulamaIdList:
    window["environment"]["favoriBolumuGosterilecekUygulamaIdList"] || "",
  menuBolumuGosterilecekUygulamaIdList:
    window["environment"]["menuBolumuGosterilecekUygulamaIdList"] || "",
  magazaBolumuGosterilecekUygulamaIdList:
    window["environment"]["magazaBolumuGosterilecekUygulamaIdList"] || "",
  RecaptchaSiteKey:
    window["environment"]["RecaptchaSiteKey"] || "",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.