import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-layout-en',
  templateUrl: './layout-en.component.html',
  styleUrls: ['./layout-en.component.scss'],
})
export class LayoutEnComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    $(function () {
      $('#main-nav').fixed();

      var api = $('.tp-banner').show().revolution({
        fullScreen: 'on',
      });

      $('span.dosyasec').on('click', function (e) {
        $('input[name="cv"]').click();
      });
    });
    var markerArr = [];

    var Lat = '39,7972333';
    var Long = '30,53315';
    var title =
      'NECDET ALPATA PAZARLAMA LOJİSTİK VE TURİZM SANAYİ VE TİCARET A.Ş.';
    var pop = 'True';
    var address = 'Zafer Mahallesi Esener Sokak No:3 Tepebaşı Eskişehir ';

    var marker = new Object({
      latitude: parseFloat(Lat.replace(/\,/g, '.')),
      longitude: parseFloat(Long.replace(/\,/g, '.')),
      html:
        "<div style='width:210px;height:90px;text-align:center;font-weight:bold'>" +
        title +
        "<p style='font-size:9pt;'>" +
        address +
        '</p></div>',
      popup: false,
    });
    markerArr.push(marker);

    var Lat = '39,7455528';
    var Long = '30,613383333333335';
    var title = 'NECDET ALPATA A.Ş. OTOMOTİV ŞUBESİ';
    var pop = 'True';
    var address =
      'Ankara Asfaltı 75.Yıl Mah. &#199;evreyolu Bulvarı No:2 Odunpazarı Eskişehir';

    var marker = new Object({
      latitude: parseFloat(Lat.replace(/\,/g, '.')),
      longitude: parseFloat(Long.replace(/\,/g, '.')),
      html:
        "<div style='width:210px;height:90px;text-align:center;font-weight:bold'>" +
        title +
        "<p style='font-size:9pt;'>" +
        address +
        '</p></div>',
      popup: false,
    });
    markerArr.push(marker);

    var Lat = '39,7460083';
    var Long = '30,613994444444447';
    var title = 'ALPATA SİGORTA ARACILIK HİZMETLERİ LTD. ŞTİ.';
    var pop = 'True';
    var address =
      'Ankara Asfaltı 75. Yıl Mah. &#199;evreyolu Bulvarı No:2 Odunpazarı Eskişehir';

    var marker = new Object({
      latitude: parseFloat(Lat.replace(/\,/g, '.')),
      longitude: parseFloat(Long.replace(/\,/g, '.')),
      html:
        "<div style='width:210px;height:90px;text-align:center;font-weight:bold'>" +
        title +
        "<p style='font-size:9pt;'>" +
        address +
        '</p></div>',
      popup: false,
    });
    markerArr.push(marker);

    var Lat = '39,7515417';
    var Long = '30,646708333333333';
    var title = 'ALP HAVACILIK SANAYİ VE TİCARET A.Ş.';
    var pop = 'True';
    var address = 'Organize Sanayi B&#246;lgesi 8. Cadde 26110 Eskişehir';

    var marker = new Object({
      latitude: parseFloat(Lat.replace(/\,/g, '.')),
      longitude: parseFloat(Long.replace(/\,/g, '.')),
      html:
        "<div style='width:210px;height:90px;text-align:center;font-weight:bold'>" +
        title +
        "<p style='font-size:9pt;'>" +
        address +
        '</p></div>',
      popup: false,
    });
    markerArr.push(marker);

    var Lat = '39,7524556';
    var Long = '30,635080555555554';
    var title =
      'ALPATA TEKNOLOJİ YAZILIM ve BİLİŞİM HİZMETLERİ SAN. ve TİC. A.Ş';
    var pop = 'True';
    var address =
      'Organize Sanayi B&#246;lgesi Bilim Caddesi Yazılım Kule No:5/401  Odunpazarı Eskişehir';

    var marker = new Object({
      latitude: parseFloat(Lat.replace(/\,/g, '.')),
      longitude: parseFloat(Long.replace(/\,/g, '.')),
      html:
        "<div style='width:210px;height:90px;text-align:center;font-weight:bold'>" +
        title +
        "<p style='font-size:9pt;'>" +
        address +
        '</p></div>',
      popup: false,
    });
    markerArr.push(marker);

    var Lat = '39,908979';
    var Long = '32,755191';
    var title = 'ALPTEKNİK HAVACILIK SANAYİ VE TİCARET A.Ş.';
    var pop = 'True';
    var address =
      'Mustafa Kemal Mahallesi Dumlupınar Bulvarı Tepeprime İş Merkezi No:266 A Blok D:35 &#199;ankaya Ankara';

    var marker = new Object({
      latitude: parseFloat(Lat.replace(/\,/g, '.')),
      longitude: parseFloat(Long.replace(/\,/g, '.')),
      html:
        "<div style='width:210px;height:90px;text-align:center;font-weight:bold'>" +
        title +
        "<p style='font-size:9pt;'>" +
        address +
        '</p></div>',
      popup: false,
    });
    markerArr.push(marker);

    $('.googleMapDiv')
      .gMap({
        zoom: 9,
        markers: markerArr,
        scrollWheel: false,
      })
      .gMap('centerAt', {
        latitude: 39.87,
        longitude: 31.6,
        zoom: 9,
      })
      .bind('init', function () {
        //$('#map').gmap('loadFusion', { 'query': { 'from': 297050 } });
      });
  }
}
