import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './pages/layout/layout-tr/layout.component';
import { LayoutEnComponent } from './pages/layout/layout-en/layout-en.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [AppComponent, LayoutComponent, LayoutEnComponent],
  imports: [CommonModule, BrowserModule, AppRoutingModule,
    ReactiveFormsModule, FormsModule,
    NgxCaptchaModule],
  providers: [],
  bootstrap: [AppComponent],

})
export class AppModule {}
