<div id="main-nav" class="navbar">
  <div class="container">

    <div class="navbar-header">

      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#site-nav">
        <span class="icon-bar"></span><span class="icon-bar"></span><span class="icon-bar"></span>
      </button>

      <!-- ======= LOGO ========-->
      <a class="navbar-brand scrollto" href="#home">
        <span class="logo-small">
          <img src="assets/images/alpata-logo.png" alt="" />
        </span>
        <span class="to-top"><i class="fa fa-arrow-up"></i></span>
      </a>

    </div>

    <div id="site-nav" class="navbar-collapse collapse">
      <ul class="nav navbar-nav navbar-right">

        <li class="sr-only">
          <a href="#home" class="scrollto">ANA SAYFA</a>
        </li>

        <li>
          <a href="#about" class="scrollto">HAKKIMIZDA</a>
        </li>
        <li>
          <a href="#portfolio" class="scrollto">ŞİRKETLER</a>
        </li>


        <li>
          <a href="#hr" class="scrollto">KARİYER</a>
        </li>
        <li>
          <a href="#contact" class="scrollto">İLETİŞİM</a>
        </li>
        <li>
          <a>|</a>
        </li>
        <li>

          <a [routerLink]="['/']" routerLinkActive="router-link-active">
            <img src="assets/images/tr.jpg" title="Türkçe" />
          </a>
        </li>
        <li>
          <a [routerLink]="['/en']" routerLinkActive="router-link-active">
            <img src="assets/images/eng.jpg" title="İngilizce" />
          </a>
        </li>
        <!-- <li>

          <a href="https://www.ekatalog.gen.tr/katalog/alpata/" target="_blank" class="" style="color: #fff !important;
    font-size: 12px;
    font-weight: 600;
    background-color: #f70f0f !important;
    color: #002d62;
    line-height: 35px;
    margin-top: 15px;
    text-transform: capitalize;
    opacity: 1;
    border-radius: 5px;">
            2021 Takvim
          </a>
        </li> -->
      </ul>

    </div>
    <!--End navbar-collapse -->

  </div>
  <!--End container -->

</div>
<ul style="z-index:100000!important;background-color:transparent;float:right; margin: 0px;"
  class="nav navbar-nav navbar-right">
  <li>
    <a style="z-index:100000!important;background-color:transparent;" [routerLink]="['/']"
      routerLinkActive="router-link-active">
      <img src="assets/images/tr.jpg" title="Türkçe" />
    </a>
  </li>
  <li>
    <a style="z-index:100000!important;background-color:transparent;" [routerLink]="['/en']"
      routerLinkActive="router-link-active">
      <img src="assets/images/eng.jpg" title="İngilizce" />
    </a>
  </li>
</ul>

<header id="home" class="jumbotron">

  <div class="tp-banner">
    <ul>
      <!-- SLIDE  -->
      <li data-transition="fade" data-slotamount="7" data-masterspeed="600" data-saveperformance="on"
        data-title="Full-Width Video">
        <!-- MAIN IMAGE -->
        <!-- LAYERS -->
        <!-- LAYER NR. 1 -->

        <img src="assets/images/slider/30935c18-6364-4a43-ba3e-9f13efd32654_slider.png" alt=""
          style="background-color: #fff;" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" />



        <!-- LAYER NR. 2 -->
        <div class="tp-caption blue_bold_bg_20 lft ltt tp-resizeme rs-parallaxlevel-0" data-x="center" data-hoffset="0"
          data-y="130" data-speed="1000" data-start="1500" data-easing="Power3.easeInOut" data-splitin="none"
          data-splitout="none" data-elementdelay="0.1" data-endelementdelay="0.1" data-endspeed="1000"
          style="z-index: 10000; max-width: auto; max-height: auto; white-space: nowrap; font-size:40pt; color:#0F1D41;">
          <img src="assets/images/alpata_logo5e1f.png?v=2" />
        </div>

        <div class="tp-caption blue_bold_bg_20 lft ltt tp-resizeme rs-parallaxlevel-0" data-x="center" data-hoffset="0"
          data-y="250" data-speed="1000" data-start="2500" data-easing="Power3.easeInOut" data-splitin="none"
          data-splitout="none" data-elementdelay="0.1" data-endelementdelay="0.1" data-endspeed="1000"
          style="z-index: 10000; max-width: auto; max-height: auto; white-space: nowrap; font-size:20pt;">
          <span class="zoom-this"> Web Sitesine Hoşgeldiniz</span>
        </div>

        <div class="daha-fazla tp-caption blue_bold_bg_20 lft ltt tp-resizeme rs-parallaxlevel-0" data-x="center"
          data-hoffset="0" data-y="320" data-speed="1000" data-start="3500" data-easing="Power3.easeInOut"
          data-splitin="none" data-splitout="none" data-elementdelay="0.1" data-endelementdelay="0.1"
          data-endspeed="1000" style="z-index: 10000; max-width: auto; max-height: auto; white-space: nowrap;">

          <a data-scroll-nav="0" href="#about" class="slide-down scrollto">
            Daha Fazla
          </a>

        </div>

        <div class="slider-overlay"></div>
      </li>

    </ul>
  </div>
</header>
<div id="preview-scroll"></div>
<router-outlet></router-outlet>

<footer id="main-footer" class="color-bg light-typo">

  <div class="container text-center">
    <ul class="social-links">



    </ul>

  </div>

</footer>
